<template>
    <div class="contactPopup" :class="{ 'contactPopupActive': isPhonePopupActive }">
        <img 
            class="closeXIcon"
            @click="closePopup"
            :src="require(`@/assets/icons/form/closeX.svg`)" 
            alt=""
        >
        <div class="row">
            <div class="singleItem">
                <img 
                    class="icon"
                    :src="require('@/assets/icons/form/location.svg')" 
                    alt=""
                >
                <div class="text">
                    <p>{{ $t("footer.header") }}</p>
                    <span>{{ $t("companyData.address") }}</span>
                </div>
            </div>
            <div class="singleItem">
                <img 
                    class="icon"
                    :src="require('@/assets/icons/form/phoneDark.svg')" 
                    alt=""
                >
                <div class="text">
                    <p>{{ $t("footer.header2") }}</p>
                    <span>{{ $t("companyData.number") }}</span>
                </div>
            </div>
            <div class="singleItem">
                <img 
                    class="icon"
                    :src="require('@/assets/icons/form/emailDark.svg')" 
                    alt=""
                >
                <div class="text">
                    <p>{{ $t("footer.header3") }}</p>
                    <a href="mailto:info@jtprojekt.ee">{{ $t("companyData.email") }}</a>
                </div>
            </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d414.99740533340616!2d24.521544680937097!3d58.38571354350563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ecfd20c9bee8bd%3A0x8c8c2baea39e4bad!2sPilli%206%2C%20P%C3%A4rnu%2C%2080013%20P%C3%A4rnu%20maakond!5e0!3m2!1set!2see!4v1680525832878!5m2!1set!2see" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</template>

<script>
export default {
    name: 'phonePopup',
    props: {
        isPhonePopupActive: null
    },  
    data() {
        return {
        
        }
    },  
    methods: {
        closePopup() {
            this.inquirySent = false
            this.$emit('closePopup')
        }
    },
}
</script>
<style scoped>
.contactPopup {
    z-index: 100000;
    position: fixed;
    top: -2000px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: 450px;
    padding-bottom: 15px;
    width: 750px;
    background: white;
    border-radius: 10px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    overflow: hidden;
}
.contactPopupActive {
    top: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.closeXIcon {
    position: absolute;
    z-index: 1000;
    right: 27px;
    top: 23px;
    width: 18px;
    cursor: pointer;
}
.row {
    width: 90%;
    margin: 0 auto;
    margin-top: 40px;
    padding-top: 25px;
    padding-bottom: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-bottom: 1px solid rgb(230,230,230);
}
.singleItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.text {
    width: 100%;
    text-align: start;
}
.icon {
    height: 35px;
    margin-right: 15px;
    margin-top: 5px;
}
.text p {
    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    color: #1B1938;
}
.text span, .text a {
    font-size: 15px;
    color: rgb(70, 90, 104);
    text-decoration: none;
}
iframe {
    width: 90%;
    height: 250px;
    margin: 0 auto;
    margin-top: 25px;
    border: 1px solid rgb(230,230,230);
    border-radius: 5px;
}
@media (max-width: 800px) {
    .contactPopup {
        width: 90%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}
@media (max-width: 450px) {
    .contactPopup {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-bottom: 25px;
    }
}
@media (max-width: 1050px) {
    .singleItem {
        width: 30%;
    }
    .singleItem:nth-child(2) {
        width: 30%;
    }
}
@media (max-width: 875px) {
    .icon {
        height: 35px;
        margin-right: 15px;
    }
    .text p {
        font-size: 18px;
    }
    .text span, .text a {
        font-size: 14px;
    }
}
@media (max-width: 670px) {
    .icon {
        height: 30px;
        margin-right: 5px;
    }
    .text p {
        font-size: 16px;
    }
    .text span, .text a {
        font-size: 12px;
    }
}

@media (max-width: 560px) {
    .row {
        margin-top: 30px;
        display: block;
        text-align: center;
        padding-bottom: 0px;
    }
    .singleItem {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .singleItem:nth-child(2) {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .icon {
        height: auto;
        width: 55px;
        margin-top: 0;
        margin-right: 10px;
    }
    .text p {
        font-size: 22px;
    }
    .text span, .text a {
        font-size: 16px;
    }
}
</style>