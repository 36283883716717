<template>
    <body :class="{ 'bodyNoPadding':  $route.name == 'home', 'bodyPadding': $route.name != 'home'}">
        <div v-if="isPageLoading">
            <loadingScreen />
        </div>
        <div v-else>
            <contactMenu
                @openContactPopup="openContactPopup"  
                @openPhonePopup="openPhonePopup"
                @openLocationPopup="openLocationPopup"
            />
            <div>
                <router-view
                    @openContactPopup="openContactPopup"
                    :localeChanged="localeChanged" 
                    :textSections="textSections"
                    :images="images"
                    :partners="partners"
                />
            </div>
            <contactPopup 
                ref="contactPopupComp"
                @closePopup="closeContactPopup" 
                :isContactPopupActive="isContactPopupActive"
            />
            <locationPopup 
                @closePopup="closeContactPopup" 
                :isLocationPopupActive="isLocationPopupActive"
            />
            <phonePopup 
                @closePopup="closeContactPopup" 
                :isPhonePopupActive="isPhonePopupActive"
            />
            <div 
                class="darkBackground" 
                :class="{'darkBackgroundActive': isContactPopupActive || isLocationPopupActive || isPhonePopupActive || isPrivacyPolicyActive}"
                v-if="isBackgroundActive"
                @click="closeContactPopup"
            ></div>
            <footerMain @openContactPopup="openContactPopup" @openPrivacyPolicy="openPrivacyPolicy" />
        </div>
        <privacyPolicy @closePrivacyPolicy="closeContactPopup" v-if="isPrivacyPolicyActive" />
    </body>
</template>

<script>
import contactPopup from "@/components/contact/popup.vue"
import phonePopup from "@/components/contact/phonePopup.vue"
import locationPopup from "@/components/contact/locationPopup.vue"
import footerMain from "@/components/menus/footer.vue"
import contactMenu from "@/components/menus/contactMenu.vue"
import privacyPolicy from "@/components/privacyPolicy.vue"
import { onMounted, onUnmounted, ref } from 'vue'
import loadingScreen from "@/components/home/loadingScreen.vue"
import tr from "@/i18n/translation"

export default {
    name: 'App',
    components: {
        contactPopup,
        locationPopup,
        phonePopup,
        footerMain,
        privacyPolicy,
        loadingScreen,
        contactMenu,
    },
    created() {
        this.getLocaleData()
        setTimeout(() => {
            this.isPageLoading = false
        }, 2400)
    },
    setup() {
        const scrollPosition = ref(0)

        const handleScroll = () => {
            scrollPosition.value = window.scrollY
        }

        onMounted(() => {
            window.addEventListener('scroll', handleScroll)
        })

        onUnmounted(() => {
            window.removeEventListener('scroll', handleScroll)
        })

        return { scrollPosition }
    },
    watch: {
        localeChanged() {
            this.getLocaleData()
        }
    },
    data() {
        return {
            isPageLoading: true,
            localeChanged: 0,
            images: null,

            isPrivacyPolicyActive: false,
            isContactPopupActive: false,
            isLocationPopupActive: false,
            isPhonePopupActive: false,
            isBackgroundActive: false,

            scrollPosToFixed: 330,
            textSections: null,
            partners: null
        }
    },
    methods: {
        getLocaleData() {
            const promise = tr.productList()
            promise.then((result) => {
                this.textSections = result.textSections
                this.images = result.images
                this.partners = result.partners
            })
        },

        openContactPopup() {
            this.isBackgroundActive = true

            setTimeout(() => {
                this.isPhonePopupActive = false
                this.isPrivacyPolicyActive = false
                this.isContactPopupActive = true
            }, 100);

        },

        openPrivacyPolicy() {
            this.isBackgroundActive = true

            setTimeout(() => {
                this.isPhonePopupActive = false
                this.isContactPopupActive = false
                this.isPrivacyPolicyActive = true
            }, 100);

        },

        openLocationPopup() {
            this.isBackgroundActive = true

            setTimeout(() => {
                this.isPhonePopupActive = true
                this.isPrivacyPolicyActive = false
                this.isContactPopupActive = false
            }, 100);

        },

        openPhonePopup() {
            this.isBackgroundActive = true

            setTimeout(() => {
                this.isContactPopupActive = false
                this.isPrivacyPolicyActive = false
                this.isPhonePopupActive = true
            }, 100);

        },

        closeContactPopup() {
            this.isContactPopupActive = false
            this.isPhonePopupActive = false
            this.isLocationPopupActive = false
            this.isPrivacyPolicyActive = false

            this.isBackgroundActive = false
            this.$refs.contactPopupComp.inquirySent = false
        },
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}
.bodyNoPadding {
    padding-top: 0;
}
.bodyPadding {
    padding-top: 70px;
}
.fixedMenu {
    position: fixed;
    top: -200px;
}
.fixedMenuActive {
    position: fixed;
    top: 00px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    /* -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3); */
}
.staticMenu {
    position: absolute;
    top: 0;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
p, span, a, input, textarea {
    margin: 0;
    font-family: 'DM sans', sans-serif;
}
h2 {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}
button {
    font-family: 'Lato', sans-serif;
}
.darkBackground {
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.darkBackgroundActive {
    z-index: 990;
    background: rgba(0, 0, 0, 0.425);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
</style>
