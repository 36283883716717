<template>
    <div class="contactPopup" :class="{ 'contactPopupActive': isLocationPopupActive }">
        <img 
            class="closeXIcon"
            @click="closePopup"
            :src="require(`@/assets/icons/form/closeX.svg`)" 
            alt=""
        >
        
    </div>
</template>

<script>
export default {
    name: 'locationPopup',
    props: {
        isLocationPopupActive: null
    },  
    data() {
        return {
        
        }
    },  
    methods: {
        closePopup() {
            this.inquirySent = false
            this.$emit('closePopup')
        }
    },
}
</script>
<style scoped>
.contactPopup {
    z-index: 100000;
    position: fixed;
    top: -2000px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: 450px;
    padding-bottom: 15px;
    width: 750px;
    background: white;
    border-radius: 10px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    overflow: hidden;
}
.contactPopupActive {
    top: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.closeXIcon {
    position: absolute;
    z-index: 1000;
    right: 27px;
    top: 23px;
    width: 18px;
    cursor: pointer;
}
@media (max-width: 800px) {
    .contactPopup {
        width: 90%;
        height: fit-content;
    }
}
@media (max-width: 450px) {
    .contactPopup {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-bottom: 25px;
    }
}
</style>