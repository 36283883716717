<template>
        <div class="container">
            <p class="closeBtn" @click="$emit('closePrivacyPolicy')">Sulge</p>
            <div class="titleContainer">
                <h2 class="title">{{ $t("privacyPolicyView.header") }}</h2>
                <div class="roundedDivider"></div>
            </div>
            <div class="paragraph" v-for="element of privacyPolicyList" :key="element.id">
                <span>{{ element.title }}</span>
                <p>{{ element.text }}</p>
            </div>
            <br>
            <p class="updatedDate">{{ $t("privacyPolicyUpdated.title") }}: {{ $t("privacyPolicyUpdated.date") }}</p>
        </div>
</template>

<script>
import tr from "@/i18n/translation"

export default {
    name: 'privacyPolicy',
    data() {
        return {
            privacyPolicyList: []
        }
    },  
    watch: {
        localeChanged() {
            this.getLocaleData()
        }
    },
    methods: {
        getLocaleData() {
            const promise = tr.productList()
            promise.then((result) => {
                this.privacyPolicyList = result.privacyPolicy
            })
        },
    },

    created() {
        this.getLocaleData()
    }
}
</script>
<style scoped>
.container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1000;
    padding: 30px 0px 10px 150px;
    width: 1000px;
    height: 90%;
    overflow-y: scroll;
    text-align: start;
    border: 1px solid rgb(240,240,240);
    border-radius: 3px;
    background: white;
}
.closeBtn {
    position: absolute;
    right: 15px;
    top: 10px;
    color: steelblue;
    text-decoration: underline;
    cursor: pointer;
}
.title {
    font-size: 40px;
}
.roundedDivider {
    width: 120px;
    height: 4px;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 50px;
    background: #4A154B;
}
.paragraph {
    margin-top: 50px;
}
.paragraph span {
    font-size: 24px;
    font-weight: bold;
}
.paragraph p {
    margin-top: 10px;
    width: 60%;
    color: rgb(50,50,50);
}
.updatedDate {
    margin-top: 35px;
    text-align: end;
    padding-right: 50px;
    font-weight: bold;
}
@media (max-width: 1350px) {
    .container {
        width: 90%;
        padding: 30px 10px 10px 20px;
    }
}
@media (max-width: 1100px) {
    .container {
        width: 95%;
        border: none;
    }
    .updatedDate {
        padding-right: 0;
    }
    .paragraph p {
        width: 70%;
    }
}
@media (max-width: 650px) {
    .title {
        font-size: 30px;
    }
    .paragraph {
        margin-top: 35px;
    }
    .paragraph p {
        width: 95%;
    }
    .container {
        width: 90%;
    }
    .roundedDivider {
        width: 120px;
        height: 3px;
        border-radius: 40px;
        margin-top: 10px;
        margin-bottom: 35px;
        background: #4A154B;
    }
}
</style>