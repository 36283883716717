<template>
    <div class="loadingScreen">
        <div class="multi-spinner-container">
            <div class="multi-spinner">
                <div class="multi-spinner">
                    <div class="multi-spinner">
                            <div class="multi-spinner">
                                <div class="multi-spinner">
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <img 
                class="logoImg"
                :src="require('@/assets/logo/jt_projekt_logo.png')" 
                alt=""
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'loadingScreen',
    data() {
        return {

        }
    },  
    methods: {
        
    },
}
</script>
<style scoped>
.loadingScreen {
    background: white;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.logoImg {
    position: absolute;
    top: -25px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 230px;
}
.multi-spinner-container {
  width: 330px;
  height: 330px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 25px;
  left: 0;
  margin: auto;
  overflow: hidden;
}

.multi-spinner {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-top-color: #ed811c;
  border-radius: 50%;
  -webkit-animation: spin 4s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  animation: spin 4s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}     
        
        
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
         transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
          
        
@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>