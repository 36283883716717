<template>
    <div class="wrapper">
        <img 
            class="flagIcon"
            @click="switchLanguage('et', newLocale)"
            :src="require('@/assets/icons/flags/et.svg')" 
            alt=""
        >
        <!-- <img 
            class="flagIcon"
            @click="switchLanguage('fi', newLocale)"
            :src="require('@/assets/icons/flags/fi.svg')" 
            alt=""
        > -->
        <img 
            class="flagIcon"
            @click="switchLanguage('en', newLocale)"
            :src="require('@/assets/icons/flags/en.svg')" 
            alt=""
        >
        <!-- <img 
            class="flagIcon"
            @click="switchLanguage('ru', newLocale)"
            :src="require('@/assets/icons/flags/ru.svg')" 
            alt=""
        > -->
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import Tr from "@/i18n/translation"
import { useRouter } from "vue-router"

export default {
    setup() {
        const { t, locale } = useI18n()
        const supportedLocales = Tr.supportedLocales
        const router = useRouter()
        const switchLanguage = async (lang, otherFunction) => {
            const newLocale = lang
            await Tr.switchLanguage(newLocale)
            try {
                await router.replace({ params: { locale: newLocale } })
            } catch(e) {
                console.log(e)
                router.push("/")
            }
            otherFunction()
        }
        return { t, locale, supportedLocales, switchLanguage }
    },

    data() {
        return {

        }
    },
    
    methods: {
        newLocale() {
            this.$emit('localeChanged')
        }
    }
}
</script>

<style scoped>
.wrapper {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.flagIcon {
    width: 30px;
    height: 30px;
    margin-left: 15px;
    cursor: pointer;
}
</style>