<template>
    <div class="contact" :class="{ 'contactFixed': scrollPosition > 60}">
        <div class="single" @click="$emit('openLocationPopup')">
            <img 
                class="icon"
                :src="require('@/assets/icons/locationWhite.svg')" 
                alt=""
            >
        </div>
        <div class="single" @click="$emit('openPhonePopup')">
            <img 
                class="icon"
                :src="require('@/assets/icons/phoneWhite.svg')" 
                alt=""
            >
        </div>
        <div class="single" @click="$emit('openContactPopup')">
            <img 
                class="icon"
                :src="require('@/assets/icons/emailWhite.svg')" 
                alt=""
            >
        </div>
    </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'

export default {
    name: 'contactMenu',
    setup() {
        const scrollPosition = ref(0)

        const handleScroll = () => {
            scrollPosition.value = window.scrollY
        }

        onMounted(() => {
            window.addEventListener('scroll', handleScroll)
        })

        onUnmounted(() => {
            window.removeEventListener('scroll', handleScroll)
        })

        return { scrollPosition }
    },
    data() {
        return {

        }
    },  
    methods: {
        
    },
}
</script>
<style scoped>

.contact {
    position: absolute;
    z-index: 1000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 140px;
    right: -300px;
    top: 80px;
    margin: auto;
    border-right: none;
    -webkit-animation: myAnimation 1s ease-in-out forwards;
            animation: myAnimation 1s ease-in-out forwards;
    -webkit-animation-delay: 1200ms;
            animation-delay: 1200ms;
}
.contactFixed {
    position: fixed;
    top: 20px;
}
.single {
    position: relative;
    background: #1B1938;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    cursor: pointer;
}
.single:hover {
    opacity: .9;
}
.icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 20px;
}
@-webkit-keyframes myAnimation {
    0% {
        right: -300px;
    }
    100% {
        right: 40px;
    }
}
@keyframes myAnimation {
    0% {
        right: -300px;
    }
    100% {
        right: 40px;
    }
}
@media (max-width: 900px) {
    .contact {
        top: 60px;
    }
    .contactFixed {
        top: 15px;
    }
    @-webkit-keyframes myAnimation {
        0% {
            right: -300px;
        }
        100% {
            right: 17px;
        }
    }
    @keyframes myAnimation {
        0% {
            right: -300px;
        }
        100% {
            right: 17px;
        }
    }
}
@media (max-width: 500px) {
    .contactFixed {
        top: auto;
        bottom: 15px;
    }
}
</style>