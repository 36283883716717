<template>
    <div :id="recaptchaId"></div>
</template>

<script>

export default {
    name: 'ReCaptcha',
    computed: {
        recaptchaId() {
            return `recaptcha`
        },
    }, 
    mounted() {
    const script = document.createElement('script')
        script.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit'
        script.async = true
        script.defer = true
        document.body.appendChild(script)

        window.onloadCallback = () => {
            window.grecaptcha.render(this.recaptchaId, {
                sitekey: '6LenUg4mAAAAAOL4cOWO63sV1rV9TCaEXV3ihbn6',
                callback: this.onRecaptchaVerified,
            })
        }
    },
    data() {
        return {
            consentAccepted: null,
        }
    },  
    methods: {
        onRecaptchaVerified(token) {
            fetch('https://jtprojekt.ee/recaptcha-verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "response": token,
                }),
            })
            .then((res) => res.json())
            .then((res) => {
                if(res.success == true) {
                    this.$emit('recaptchaSuccess')
                } else {
                    this.$emit('recaptchaFailed')
                }
            })
        },
    },
}
</script>
<style scoped>

</style>