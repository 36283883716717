<template>
    <div class="wrapper">
        <div class="width">
            <div class="row">
                <div class="singleItem">
                    <img 
                        class="icon"
                        :src="require('@/assets/icons/form/location.svg')" 
                        alt=""
                    >
                    <div class="text">
                        <p>{{ $t("footer.header") }}</p>
                        <span>{{ $t("companyData.address") }}</span>
                    </div>
                </div>
                <div class="singleItem">
                    <img 
                        class="icon"
                        :src="require('@/assets/icons/form/phoneDark.svg')" 
                        alt=""
                    >
                    <div class="text">
                        <p>{{ $t("footer.header2") }}</p>
                        <span>{{ $t("companyData.number") }}</span>
                    </div>
                </div>
                <div class="singleItem">
                    <img 
                        class="icon"
                        :src="require('@/assets/icons/form/emailDark.svg')" 
                        alt=""
                    >
                    <div class="text">
                        <p>{{ $t("footer.header3") }}</p>
                        <a href="mailto:info@jtprojekt.ee">{{ $t("companyData.email") }}</a>
                    </div>
                </div>
            </div>
    
            <div class="mainBody">
                <div class="logo">
                    <img 
                        class="logoImg"
                        :src="require('@/assets/logo/jt_projekt_logo.png')" 
                        alt=""
                    >
                    <img 
                        class="creditRaport"
                        @click="openLink()"
                        :src="require('@/assets/icons/footer/jtprojektRaport.png')" 
                        alt=""
                    >
                </div>
                <div class="data">
                    <p>{{ $t("footer.smallHeader") }}</p>
                    <hr class="line">
                    <span>{{ $t("companyData.name") }}</span>
                    <br style="margin-bottom: 10px">
                    <span>Reg. {{ $t("companyData.regNr") }} ( {{ $t("companyData.kmNr") }} )</span>
                    <br style="margin-bottom: 10px">
                    <span>{{ $t("companyData.address") }}</span>
                </div>
                <div class="data">
                    <p>{{ $t("footer.smallHeader1") }}</p>
                    <hr class="line">
                    <button @click="$emit('openContactPopup')">{{ $t("buttons.contact") }}</button>
                </div>
                <languageSwitcher class="languageSwitcher" />
            </div>
    
            <div class="bottom">
                <span>{{ $t("footer.disclaimer") }}</span>
                <p @click="$emit('openPrivacyPolicy')">{{ $t("footer.privacyBtn") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import languageSwitcher from "@/components/languageSwitcher.vue"

export default {
    name: 'footerMain',
    components: {
        languageSwitcher
    },
    data() {
        return {

        }
    },  
    methods: {
        openLink() {
            const newTab = window.open('https://krediidiraportid.ee/jt-projekt-ou', '_blank');
            newTab.focus();
        }
    },
}
</script>
<style scoped>
.wrapper {
    padding-top: 30px;
    background: #E2E4E9;
    -webkit-clip-path: ellipse(150% 100% at bottom center);
            clip-path: ellipse(150% 100% at bottom center);
}
.width {
    position: relative;
    width: 1300px;
    margin: 0 auto;
}
.row {
    padding-top: 25px;
    padding-bottom: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-bottom: 1px solid rgb(210,210,210);
}
.singleItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 300px;
}
.singleItem:nth-child(2) {
    width: 400px;
}
.text {
    width: 100%;
    text-align: start;
}
.icon {
    height: 45px;
    margin-right: 25px;
    margin-top: 2px;
}
.text p {
    font-size: 23px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    color: #1B1938;
}
.text span, .text a {
    font-size: 17px;
    color: rgb(70, 90, 104);
    text-decoration: none;
}

.mainBody {
    margin-top: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.logo {
    width: 300px;
}
.logoImg {
    width: 270px;
    margin-left: -30px;
}
.creditRaport {
    width: 300px;
    margin-top: 10px;
    margin-left: -5px;
    cursor: pointer;
}

.data {
    width: 300px;
    text-align: start;
}
.data:nth-child(2) {
    width: 400px;
}
.data p {
    font-size: 24px;
    color: #3a3a3a;
}
.data span {
    font-size: 18px;
    color: rgb(70, 90, 104);
    font-family: 'DM sans', sans-serif;
    font-weight: 400;
}
.line {
    height: 1px;
    width: 70px;
    border-radius: 100px;
    margin-bottom: 20px;
    margin-left: 1px;
    margin-top: 5px;
    background: #ed811c;
    color: #ed811c;
    border: 1px solid #ed811c;
}
button {
    margin-top: 15px;
    width: 300px;
    height: 50px;
    font-size: 24px;
    background: #ed811c;
    border: 1px solid rgba(220, 220, 220, 0);
    border-radius: 10px;
    color: white;
}
button:hover {
    cursor: pointer;
    opacity: .9;
}
.bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-top: 1px solid rgb(210,210,210);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 25px;
}
.bottom span {
    color: rgb(150,150,150);
}
.bottom p {
    cursor: pointer;
}
.languageSwitcher {
    position: absolute;
    right: 0;
    bottom: 60px;
}

@media (max-width: 1350px) {
    .width {
        width: 95%;
    }
}
@media (max-width: 1050px) {
    .singleItem {
        width: 30%;
    }
    .singleItem:nth-child(2) {
        width: 30%;
    }
    .logo {
        width: 30%;
    }
    .logoImg {
        width: 90%;
        margin-left: -30px;
    }
    .creditRaport {
        position: relative;
        z-index: 10;
        width: 100%;
    }

    .data {
        width: 30%;
    }
    .data:nth-child(2) {
        width: 30%;
    }
    button {
        position: relative;
        z-index: 10;
        width: 90%;
        height: 50px;
    }
}
@media (max-width: 875px) {
    .icon {
        height: 35px;
        margin-right: 15px;
    }
    .text p {
        font-size: 18px;
    }
    .text span, .text a {
        font-size: 14px;
    }
    .data:nth-child(3){
        display: none;
    }
    .data {
        position: relative;
        z-index: 10;
        width: 100%;
        border: 1px solid black;
    }
    .data:nth-child(2) {
        width: 70%;
    }
    .logo {
        width: 50%;
    }
    .languageSwitcher {
        right: auto;
        left: 0;
    }
    .mainBody {
        margin-top: 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack:start;
            -ms-flex-pack:start;
                justify-content:flex-start;
    }
}
@media (max-width: 670px) {
    .icon {
        height: 30px;
        margin-right: 5px;
    }
    .text p {
        font-size: 16px;
    }
    .text span, .text a {
        font-size: 12px;
    }
}
@media (max-width: 500px) {
    .data:nth-child(2) {
        display: none;
    }
    .languageSwitcher {
        right: 0;
        left: auto;
    }
    .logo {
        width: 100%;
    }
    .bottom span {
        display: none;
    }
}
@media (max-width: 560px) {
    .row {
        margin-top: -30px;
        display: block;
        text-align: center;
    }
    .singleItem {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .singleItem:nth-child(2) {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .icon {
        height: auto;
        width: 55px;
        margin-top: 0;
        margin-right: 10px;
    }
    .text p {
        font-size: 22px;
    }
    .text span, .text a {
        font-size: 16px;
    }
}
</style>