import { createRouter, createWebHistory, RouterView } from 'vue-router'
import Tr from "@/i18n/translation"

const routes = [
    {
        path: "/:locale?",
        component: RouterView,
        beforeEnter: Tr.routeMiddleware,
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('../views/homeView.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes
})

export default router